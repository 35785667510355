/* You can add global styles to this file, and also import other style files */

@import "style-variables.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

tr.mat-row {
  height: 30px !important;
  overflow: initial !important;
  word-wrap: normal !important;
  white-space: nowrap !important;
}

tr.mat-header-row {
  height: 40px !important;
}

.mat-tab-body-wrapper {
  height: 100%;
}
